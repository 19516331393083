import React from "react";
import { Button } from "react-bootstrap";
import "./TrackLoad.css"

function TruckLoad() {
  return (
    <div>
      <div className="Truckladomgconatnet">
        <div className="truckcontinrprid">
          <div className="trictextaread">
            <div className="testallwrapper">
            <h3>Shippers</h3>
            <h2>Truckload capacity without compromise</h2>
            <p>
              Convoy provides unmatched flexibility with uncompromising quality,
              reducing shipping costs and helping you achieve your
              sustainability goals.
            </p>
            <Button variant="primary" className="m-2">
                    Lean More ...
                  </Button>
            </div>
          </div>
          <div className="truckikagesiosde">
            <img src="/img/b3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TruckLoad;
