import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "./Navbar.css";
import { MdClose } from "react-icons/md";
import { BiMenu } from "react-icons/bi";
import { Button } from "react-bootstrap";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      {/* <Topbar/> */}
      <nav className="navbar1">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src="/img/logo.png" alt="" />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <MdClose /> : <BiMenu />}
          {/* <i className={click ? "fas fa-times" : "fas fa-bars"} /> */}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className="nav-links"
              onClick={closeMobileMenu}
              style={{ color: "#ed1846" }}
            >
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/service" className="nav-links" onClick={closeMobileMenu}>
              Services
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              About
            </Link>
          </li> */}

          <li className="nav-item">
            <Link
              to="/track_items"
              className="nav-links"
              onClick={closeMobileMenu}
            >
             <Button
                    variant="primary"
                    className="w-100">
                    Track
                  </Button>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
