
import React, {useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Alert, Button, Col, Form, Modal, Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import "./Admin.css";
import firebase from "firebase";
import { useAuth } from "../CreateUser.js/UserAuth";
import { database } from "../../firebase";
import { getRandomString } from "../../Services/GetRandomNumber";
import moment from "moment";


function NewShipment() {
 
    const [showa, setShowa] = useState(true);
    const handleClosea = () => {
        history.push("/auth/admin/")
    };
    const [loading, setlaoding] = useState(false);
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [email, setemail] = useState("");
    const [address1, setaddress1] = useState("");
    const [contactaddress, setcontactAddress] =  useState("")
    const [contactname, setcontactname] = useState("");
    const [contactemail, setcontactemail] = useState("");
    const [comment, setcomment] = useState("");
    const [phone, setcontactphone] = useState("");
    const [user, setuser] = useState([]);
    const history = useHistory();
    const { uid } = useAuth().currentUser;
    const [item, setitem] = useState("");
    const [weigth, setweight] = useState("");
    const [payment, setpayment] = useState("");
    const [deliverydate, setdeliverydate] = useState("");
    const [PickUpdate, setpickupdate] = useState("");
    const [createmessage, setcreatemessage] = useState("")
    const [personalphone, setphone] = useState("")
    
    const ddate = moment(new Date(deliverydate), "MM-DD-YYYY").format('DD/MM/YYYY')
    const pickup = moment(new Date(PickUpdate), "MM-DD-YYYY").format('DD/MM/YYYY')   
    
    const handlecreaterequest = async (e) => {
        e.preventDefault();
        const id = database.collection("_").doc().id;
        const trackNo = getRandomString(15, "1234567890");
       
        const trackUrl = getRandomString(
          24,
          "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm!@#$%^&*1234567890"
        );
    
        const Accesskey = getRandomString(
          7,
          "QWERTYUIOPASDFGHJKLZXCVBNM1234567890"
        );
        const data = {
          id: id,
          firstname: firstname,
          lastname: lastname,
          email: email,
          address1: address1,
          weigth: weigth,
          PickUpdate: pickup,
          deliverydate: ddate,
          payment: payment,
          personalphone:personalphone,
          contactname: contactname,
          contactnumber: phone,
          contactemail: contactemail,
          contactaddress,
          shipmentDescriptiom: item,
          Destination: comment,
          currentdestination: "",
          status: "pending",
          active: false,
          delivered: false,
          accepted: true,
          reciveved: true,
          AccessKey: Accesskey,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          deliverydate: deliverydate,
          deliverydays: 0,
          trackNo: trackNo,
          trackurl: trackUrl,
        };
    
        try {
          setlaoding(true);
    
          await database
            .collection("services")
            .doc(data.id)
            .set(data)
            .then((e) => {
              setcreatemessage(`Great job ${user.firstname} ${user.lastname} <br/> you have successfully created a shipment request with track no. : 
              ${trackNo}.<br/> A unquie url for this request has being created. <br/>
               kindly visit http://localhost:3000/shipment/tracker/${trackNo}.
               <br/> Note that this Link can only be access via Access key linked to this request`);
        
            })
            .catch((e) => {
              
            });
        } catch (error) {
          setcreatemessage(error.message);
        }
    
      };
  return (
    <div>
       
  <Modal show={showa} onHide={handleClosea}>
        <Modal.Body>
          <h1 className="workid">Create new shipment request</h1>
          <hr />
          <Form>
            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setfirstname(event.target.value);
                  }}
                  required
                />
              </Col>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  laceholder="Last name"
                  onChange={(event) => {
                    setlastname(event.target.value);
                  }}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                onChange={(event) => {
                  setphone(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setaddress1(event.target.value);
                }}
                required
              />
            </Form.Group>

            <h1 className="workid">Shipment Information</h1>

            <Row>
              <Col>
                <Form.Control
                  placeholder="Weight "
                  onChange={(event) => {
                    setweight(event.target.value);
                  }}
                  required
                />
              </Col>
              {/* <Col>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    setpayment(event.target.value);
                  }}
                  required
                >
                  <option>Select Payement Option</option>
                  <option value="Payment on Delivery">
                    Payment on Delivery
                  </option>
                  <option value="Online Payment">Online Payment</option>
                  <option value="Bank Payement">Bank Payement</option>
                </Form.Select>
              </Col> */}
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Delivery Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(event) => {
                    setdeliverydate(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Pick-Up Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(event) => {
                    setpickupdate(event.target.value);
                  }}
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Shipment Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setitem(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Destination</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setcomment(event.target.value);
                }}
                required
              />
            </Form.Group>

            <h1 className="workid">Contact Person Information</h1>
            <hr />
            <Row>
              <Col>
                <Form.Control
                  placeholder="Contact Person Name"
                  onChange={(event) => {
                    setcontactname(event.target.value);
                  }}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Phone Number"
                  onChange={(event) => {
                    setcontactphone(event.target.value);
                  }}
                  required
                />
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setcontactemail(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label> Contact Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setcontactAddress(event.target.value);
                }}
                required
              />
            </Form.Group>
            {createmessage ? <Alert variant="success"><p>{createmessage}</p></Alert> : ""}

            <Button
              variant="dark"
              type="submit"
              disabled={loading}
              className="w-100 "
              onClick={handlecreaterequest}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default NewShipment