import React from "react";
import "./Global.css";

function Global() {
  return (
    <div>
      <div
        className="globalconatiner"
        style={{ backgroundImage: "url(/img/b1.jpg)" }}
      >
        <div className="globalrapper">
        <div className="globalcontent">
          <div className="gloabalheader">
            UNLEASH GLOBAL REACH FOR FASTER DELIVERIES WORLDWIDE
          </div>
          <div className="globalcotent">
            Leverage our expansive warehousing and distribution network
            including 41 branch offices around the globe. Each of our
            state-of-the-art facilities maintains rigorous quality assurance
            standards, running on high-performance inventory management
            technologies with current operating capabilities, and unmatched
            customer service.
          </div>

          <button>Explore ... </button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Global;
