import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Component/Client/Home';
import { AuthProvider } from "./Component/CreateUser.js/UserAuth";
import Login from "./Component/CreateUser.js/Login"
import Register from "./Component/CreateUser.js/Register"
import Forgetpassword from "./Component/CreateUser.js/Forgetpassword"
import Validator from "./Component/CreateUser.js/Validator"
import Loading from './Loading';
import Contact from './Component/Client/Contact';
import TrackShipment from './Component/Client/TrackShipment';
import About from './Component/Client/About';
import Services from './Component/Client/Services';
import PrivateRoute from "./Component/CreateUser.js/PrivateRoute"
import Dashboard from './Component/Admin/Dashboard';
import Createship from './Component/Admin/CreateShipment'
import Shipmentdetails from './Component/Admin/Shipmentdetails';
import NewShipment from './Component/Admin/NewShipment';
import TrackerHistory from './Component/Admin/TrackerHistory';
import PageNotFound from './Component/PageNotFound';



function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="App">
    
      {loading ? (
        <>
        <Loading/>
        </>
      ) : (
        <Router>
     <AuthProvider>
 <Switch>
      
      <Route path="/" exact component={Home}/>
      <Route path="/user/:id" exact component={Home}/>
      <Route path="/auth/login" exact component={Login}/>
      <Route path="/contact" exact component={Contact}/>
      <Route path="/auth/register" exact component={Register}/>
      {/* <Route path="/auth/forget_password" exact component={Forgetpassword}/> */}
      <Route path="/checker" exact component={Validator}/>
      <Route path="/track_items" exact component={TrackShipment}/>
      <Route path="/about" exact component={About}/>
      <Route path="/service" exact component={Services}/>
      <PrivateRoute path="/auth/admin/:id" exact component={Dashboard}/>
      <PrivateRoute path="/auth/admin" exact component={Dashboard}/>
      <PrivateRoute path="/dashboard/index" exact component={Dashboard}/>
      <PrivateRoute path="/Component/Admin/Shipment" exact component={Createship}/>
      <PrivateRoute path="/new_shipment/Admin" exact component={NewShipment}/>
      <PrivateRoute path="/tracker/history" exact component={TrackerHistory}/>
      <Route path="/shipment/tracker/:trackNo" exact component={Shipmentdetails}/>



      <Route path="*" component={PageNotFound} />
    </Switch>
 </AuthProvider>
 
  </Router>

      )}

      
    </div>
  );


}

export default App;
