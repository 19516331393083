import React from 'react'
import Leasding from './Leasding'
import Banner from './Banner'
import DeliverPassion from './DeliverPassion'
import Footer from './Footer'
import Global from './Global'
import Navbar from "./Navbar"
import Tracklaod from "./TruckLoad"
import OneBest from './OneBest'

function Home() {
  return (
    <div>
      <Navbar/>
      <Banner/>
      <Leasding/>
      <OneBest/>
      <Tracklaod/>
      {/* <Global/> */}
      <DeliverPassion/>
      <Footer/>
    </div>
  )
}

export default Home