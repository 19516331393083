import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./Delivery.css"

function DeliverPassion() {

  const history =  useHistory()


  const handetrack = ()=>{
    history.push("/track_items")
  }
  return (
    <div>
      <div className="deliveryconatainer">
        <div className="deliverycontent">
          <div className="deliverywrapper">
            <div className="deliveryconten11">
              <img src="/img/b2 (4).jpg" alt="" />
            </div>
            <div className="deliveryconten">
              <div className="deliverycontentheader1">
                <h1 className="text-dark">WE DELIVER PASSION</h1>
              </div>
              <div className="deliverycontentheader1">
               <p className="text-dark"> At TransCargo  , we are constantly expanding our reach to better serve
                our international client base. Our commanding global presence
                includes over forty locations around the world. Learn what’s
                possible with a partner who goes further than borders and
                boundaries</p>.
              </div>

              <div className="deliverycontentheader">
                <div className="deliveryimages">
                <img src="/img/b2 (2).jpg" alt="" />
                </div>
                <div className="deliveryimages">
                <img src="/img/b2 (1).jpg" alt="" />
                </div>
                <div className="deliveryimages">
                <img src="/img/b3.jpg" alt="" /> 
                </div>
              </div>

              <Button variant="primary"  className="my-2" onClick={handetrack}>Track  Shipment</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliverPassion;
