import React, { useCallback, useEffect, useState } from 'react'
import { getallrequest } from '../../Services/GetUser.service';
import { Alert, Button, Col, Form, Modal, Table } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import "./Admin.css";
import firebase from "firebase";
import { useAuth } from "../CreateUser.js/UserAuth";
import { database } from "../../firebase";
import {AiFillDelete} from "react-icons/ai"
import { format } from "date-fns";
import moment from 'moment';




function DashboardINdex() {
  
    const [userCollection, setuserCollection] = useState([]);
    const [lastDocs, setlastDocs] = useState();
    const [isEmpty, setisEmpty] = useState(false);
    const [showa, setShowa] = useState(false);
  const [loading, setlaoding] = useState(false);
  const [message, setmessage] = useState("");
  const [edit, setEdititem] = useState([]);
  const [showedit, seteditShow] = useState(false);
  const handleCloseedit = () => seteditShow(false);
  const handleShowedit = () => seteditShow(true);
  const [location, setcurrentloacation] = useState("");
  const [date, setDeliverdate] = useState("");
  const [newstatus, setstatus] = useState("");
  const [remark, setremark] = useState("");
  const [showmessage, setShowmessage] = useState(false);
  const handleClosemessage = () => setShowmessage(false);
  const handleShowmessage = () => setShowmessage(true);
  const [PickUpdate, setPickup]= useState("")
  const newdate = moment(new Date(date), "MM-DD-YYYY").format('DD/MM/YYYY')
  const pickup = moment(new Date(PickUpdate), "MM-DD-YYYY").format('DD/MM/YYYY')  
    useEffect(() => {
        getallrequest().then((collections) => {
          updateState(collections);
        });
      }, []);

      const handleUpdate = async (e) => {
        e.preventDefault();
  
        const id = database.collection("_").doc().id;
        
        const data = {
          id: id,
          trackid: edit.id,
          firstname: edit.firstname,
          lastname: edit.lastname,
          email: edit.email,
          address1: edit.address1,
          personalphone: edit.personalphone,
          weigth: edit.weigth,
          payment: edit.payment,
          status:newstatus, 
          contactname: edit.contactname,
          contactAddress : edit.contactaddress,
          contactnumber: edit.contactnumber,
          item: location,
          contactemail: edit.contactemail,
          comment: remark,
          shipmentDescriptiom: edit.shipmentDescriptiom,
          Destination: edit.Destination,
          AccessKey: edit.AccessKey,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          deliverydate: newdate,
          PickUpdate : pickup,
          deliverydays: 0,
          trackNo: edit.trackNo,
          trackurl: edit.trackurl,
        };
    
        try {
          setlaoding(true);
          await database.collection("Tracker").doc(data.id).set(data);
        } catch (error) {
          setmessage(error.message);
        }
    
        await database
          .doc(`services/${edit.id}`)
          .update({
            status: newstatus,
            remark: remark,
            currentdestination: location,
            deliverydate: newdate,
          })
          .then(() => {
            setmessage("Be informed that, shipment record has being updated");
          })
          .catch((e) => {
           
            setmessage(e.message);
          });
        setlaoding(false);
        
      };
    

      const updateState = (users) => {
        const isItemEmpty = users.length === 0;
        if (!isItemEmpty) {
          const lastDoc = users[users.length - 1];
          setuserCollection(users);
          setlastDocs(lastDoc);
        } else {
          setisEmpty(true);
        }
        setlaoding(false);
      };
      const fetchMore = () => {
        setlaoding(true);
        getallrequest(lastDocs).then((collections) => {
          updateState(collections);
        });
      };

      const handleCloaseshipment = ()=>{

      }

      const Togglemodal = useCallback(
        (item) => () => {
             setEdititem(item);
       
          if (edit.status === "Delivered") {
            setmessage("shipment closed");
            handleShowmessage();
            return;
          }
    
          handleShowedit();
        },
        []
      );

  
      const Togglemodal1 = useCallback(
        (item) => async (e) => {
          e.preventDefault()
          console.log(item.id)
         await database.collection("services").doc(item.id).delete().then(()=>{
          window.location.reload()
         }).catch((e)=>{
            console.log(e.message)
         })
        },
        []
      );;
  return (
    <div>
        <Modal show={showedit} onHide={handleCloseedit}>
        <Modal.Body>
          <div className="tractbody">
            <div className="trackdetails">
              <div className="headertracker">
                <h1>Update Shipment Information</h1>
              
              </div>

              <div className="trackingdetailtoupdate">
                <div className="detailsrapper">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Tracker Number</Form.Label>
                      <Form.Control placeholder={edit.trackNo} disabled />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Sender Name</Form.Label>
                      <Form.Control
                        placeholder={`${edit.firstname} ${edit.lastname}`}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Reciver Name</Form.Label>
                      <Form.Control placeholder={edit.contactname} disabled />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Destination</Form.Label>
                      <Form.Control placeholder={edit.Destination} disabled />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Request Date</Form.Label>
                      <Form.Control
                        placeholder={
                          edit.created
                            ? format(edit.created, "dd/MM/yyyy hh:mm a")
                            : ""
                        }
                        disabled
                      />
                    </Form.Group>

                    <Form.Select
                      aria-label="Default select example"
                      onChange={(event) => {
                        setstatus(event.target.value);
                      }}
                    >
                      <option>Change Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Pick-Up">Pick-Up</option>
                      <option value="On-Hold">On-Hold</option>
                      <option value="In Transit">In Transit</option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Delivered">Delivered</option>
                    </Form.Select>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Expected Delivery Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(event) => {
                          setDeliverdate(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Expected Pick-Up Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(event) => {
                          setPickup(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Current Location</Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(event) => {
                          setcurrentloacation(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Remark</Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(event) => {
                          setremark(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>

                    {message ? <Alert variant="success">{message}</Alert> : ""}

                    <Button
                      variant="dark"
                      disabled={loading}
                      className="w-100 py-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="accountiformationheader">
        <div className="textalejdjd">
          <h1>Control Panel</h1>
          <p> Shipment Center</p>
        </div>

        <div className="shipmentconatiner">
          <div className="creatnewshipment">
          </div>
          <div className="histortconetnt">
            <Table striped>
              <thead>
                <tr className="bg-dark text-light">
                  <th>#</th>
                  <th>Delete</th>
                  <th>Date</th>
                  <th>Sender Name</th>
                  <th>Email</th>
                  <th>Track No</th>
                  <th>Receiver Name</th>
                  <th>Receiver Number</th>
                  <th>Receiver Email</th>
                  <th>Action</th>
                  <th>Shiping</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userCollection.map((item, id) => {
                  return (
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td onClick={Togglemodal1(item)}><AiFillDelete/></td>
                      <td>
                        {item.created
                          ? format(item.created, "dd/MM/yyyy hh:mm a")
                          : ""}
                      </td>
                      <td>
                        {item.lastname} {item.firstname}
                      </td>
                      <td>{item.email}</td>
                      <td>{item.trackNo}</td>
                      <td>{item.contactname}</td>
                      <td>{item.contactnumber}</td>
                      <td>{item.contactemail}</td>
                      <td>
                        <BiEdit onClick={Togglemodal(item)} />
                      </td>
                      <td>
                        {item.active === true
                          ? "Shiping in Progress"
                          : "Shiping Pending"}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            item.delivered === true ? " green" : "red",
                        }}
                      >
                        {item.delivered === true ? "Closed" : "Open"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {loading && <div className="text-center"> loading ... </div>}
            {!loading && !isEmpty && (
              <div className="text-center textsss">
                <button onClick={fetchMore}>see more</button>
              </div>
            )}
            {isEmpty && <h1 className="hshhs">no more data to fetch</h1>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardINdex