import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import './Admin.css'
import { getuser } from '../../Services/GetUser.service';
import { useAuth } from '../CreateUser.js/UserAuth';
import {BiLogOut} from "react-icons/bi"


function AdminHeader() {
const { uid} = useAuth().currentUser;
const [user, setuser] =  useState([])
const { logout } = useAuth();
const history =  useHistory()

    useEffect(() => {
        getuser(uid, (user) => {
           setuser(user[0])
          })
      }, []);

      const handleLogout = async() =>{
        try {
            await logout();
            history.push("/auth/login");
          } catch (e) {
            
          }
      }

      
  return (
    <div>
        <div className="adminheadercontainer">
            <div className="admincontent">
                <div className="adminas">
                    <div className="logoside">
                        <Link to="/auth/admin/"><img src="/img/logo.png" alt="" /></Link>
                    </div>
                    <div className="conentsode">
                        <div className="name"> {user.firstname} {user.lastname} </div>
                        <div className="roleuser"> Role :  {user.permission} </div>
                        <button onClick={handleLogout}><BiLogOut/></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminHeader