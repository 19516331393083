import React from "react";
import './Admin.css'
import { useHistory } from "react-router-dom";
import SideMenue from "./SideMenu";
import DashboardINdex from "./DashboardINdex";

function Dashboard() {
  const history = useHistory()



  return (
    <div>
        <SideMenue/>
        <DashboardINdex/>
      
    </div>
  );
}

export default Dashboard;
