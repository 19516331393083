import React, { useEffect, useState} from "react";
import {Offcanvas} from "react-bootstrap";
import { HiMenuAlt1 } from "react-icons/hi";
import "./Menu.css";
import { database} from "../../firebase";
import { useAuth } from "../../Component/CreateUser.js/UserAuth";
import { useHistory } from "react-router-dom";
;


function SideMenue() {
  const { uid } = useAuth().currentUser;
  const history = useHistory()
  const [show, setShow] = useState(true);
  const ref = database.doc(`users/${uid}`);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [user, setuser] = useState([]);
  const { logout } = useAuth();

  


  const handlebackHome = (e) =>{
    e.preventDefault()
    history.push(`/auth/admin/${user.id}`)
  }

  const handlerequestpage = (e) =>{
    e.preventDefault()
    history.push(`/new_shipment/Admin`)
  }

  const handleredashboard = (e) =>{
    e.preventDefault()
    history.push(`/dashboard/index`)
  }

  const handleworkspace = (e)=>{
    e.preventDefault()
    history.push(`/tracker/history`)

  }

  const handlePayment= (e)=>{
    e.preventDefault()
    history.push(`/payment/history/${user.id}`)
  }

  const handleconcept = (e)=>{
    e.preventDefault()
    history.push(`/send/message/${user.id}`)
  }

   async function handleLogout() {
      try {
        await logout();
        history.push("/auth/login");
      } catch (e) {
        
      }
    }
  return (
    <div>
      <>
      
        <div className="dashboardmenueICon" onClick={handleShow}>
          <HiMenuAlt1 />
        </div>

        <div className="convascontainer">
          <Offcanvas show={show} onHide={handleClose}>
            <div className="titleDashboard">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Dashboard</Offcanvas.Title>
              </Offcanvas.Header>
            </div>
            <Offcanvas.Body>
              <div className="imagecontent">
                <div className="imageitem">
                  <div className="mageside">
                   {user.image ?  <img src="/img/logo.png" alt="" /> : <img src="/img/logo.png" alt="" />}
                    <div className="editimage">
                      <> 
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menuesidecanvas">
              <div className="Request" onClick={handleredashboard}>
                    My Dashboard
                </div>
                <div className="Request" onClick={handlerequestpage}>
                  Create Shipment
                </div>
                <div className="Request" onClick={handleworkspace}>
                  Traker History
                </div>
                
                <div className="Request" onClick={handlebackHome}>
                  Back Home
                </div>
                <div className="Request" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </>
    </div>
  );
}

export default SideMenue;
