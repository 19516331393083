import React, { useCallback, useEffect, useState } from "react";
import { getuser, getallrequest } from "../../Services/GetUser.service";
import { Link, useHistory } from "react-router-dom";
import { Alert, Button, Col, Form, Modal, Table } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import AdminHeader from "./AdminHeader";
import Row from "react-bootstrap/Row";
import "./Admin.css";
import firebase from "firebase";
import { useAuth } from "../CreateUser.js/UserAuth";
import { database } from "../../firebase";
import { getRandomString } from "../../Services/GetRandomNumber";
import { format } from "date-fns";

function Dasboard(props) {
  const [userCollection, setuserCollection] = useState([]);
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);
  const [showa, setShowa] = useState(false);
  const handleClosea = () => setShowa(false);
  const handleShowtransger = () => setShowa(true);
  const [loading, setlaoding] = useState(false);
  const [message, setmessage] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [address1, setaddress1] = useState("");
  const [contactname, setcontactname] = useState("");
  const [contactemail, setcontactemail] = useState("");
  const [city, setcity] = useState("");
  const [contactstate, setcontactstate] = useState("");
  const [zip, setzip] = useState("");
  const [comment, setcomment] = useState("");
  const [phone, setcontactphone] = useState("");
  const [user, setuser] = useState([]);
  const history = useHistory();
  const { uid } = useAuth().currentUser;
  const [item, setitem] = useState("");
  const [edit, setEdititem] = useState([]);
  const [showedit, seteditShow] = useState(false);
  const handleCloseedit = () => seteditShow(false);
  const handleShowedit = () => seteditShow(true);
  const [location, setcurrentloacation] = useState("");
  const [date, setDeliverdate] = useState("");
  const [pickupdate, setPickUpDate] = useState("")
  const [newstatus, setstatus] = useState("");
  const [remark, setremark] = useState("");
  const [showmessage, setShowmessage] = useState(false);
  const [weigth, setweight] = useState("");
  const [payment, setpayment] = useState("");
  const [deliverydate, setdeliverydate] = useState("");
  const [PickUpdate, setpickupdate] = useState("");
  const [createmessage, setcreatemessage] = useState("")
  const [personalphone, setphone] = useState("")
  const handleClosemessage = () => setShowmessage(false);
  const handleShowmessage = () => setShowmessage(true);
  const [contactAddress, setContactAddress] = useState("")

  useEffect(() => {
    getallrequest().then((collections) => {
      updateState(collections);
    });
  }, []);
  const newdate = new Date(date).toLocaleString();
  const ddate = new Date(deliverydate).toLocaleString();
  const pickup = new Date(PickUpdate).toLocaleString();
  const pictupdate = new Date(pickupdate).toLocaleString(); 


  useEffect(() => {
    if (user) {
      getuser(uid, (user) => {
        setuser(user[0]);
      });

      if (user.permission === "user") {
        history.push("/login");
      }
    }
  }, []);
  const handlecreaterequest = async (e) => {
    e.preventDefault();
    const id = database.collection("_").doc().id;
    const trackNo = getRandomString(15, "1234567890");

    const trackUrl = getRandomString(
      24,
      "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm!@#$%^&*1234567890"
    );

    const Accesskey = getRandomString(
      7,
      "QWERTYUIOPASDFGHJKLZXCVBNM1234567890"
    );
    const data = {
      id: id,
      firstname: firstname,
      lastname: lastname,
      email: email,
      address1: address1,
      weigth: weigth,
      PickUpdate: pickup,
      deliverydate: ddate,
      contactAddress: contactAddress,
      payment: payment,
      personalphone:personalphone,
      contactname: contactname,
      contactnumber: phone,
      contactemail: contactemail,
      contactstate,
      contactstate,
      zip: zip,
      shipmentDescriptiom: item,
      Destination: comment,
      currentdestination: "",
      status: "pending",
      active: false,
      city: city,
      delivered: false,
      accepted: true,
      reciveved: true,
      AccessKey: Accesskey,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      deliverydate: deliverydate,
      deliverydays: 0,
      trackNo: trackNo,
      trackurl: trackUrl,
    };

    try {
      setlaoding(true);

      await database
        .collection("services")
        .doc(data.id)
        .set(data)
        .then((e) => {
          setcreatemessage(`Great job ${user.firstname} ${user.lastname} <br/> you have successfully created a shipment request with track no. : 
          ${trackNo}.<br/> A unquie url for this request has being created. <br/>
           kindly visit http://localhost:3000/shipment/tracker/${trackNo}.
           <br/> Note that this Link can only be access via Access key linked to this request`);
    
        })
        .catch((e) => {
          
        });
    } catch (error) {
      setcreatemessage(error.message);
    }

  };

  const updateState = (users) => {
    const isItemEmpty = users.length === 0;
    if (!isItemEmpty) {
      const lastDoc = users[users.length - 1];
      setuserCollection(users);
      setlastDocs(lastDoc);
    } else {
      setisEmpty(true);
    }
    setlaoding(false);
  };
  const fetchMore = () => {
    setlaoding(true);
    getallrequest(lastDocs).then((collections) => {
      updateState(collections);
    });
  };

  const Togglemodal = useCallback(
    (item) => () => {
      setEdititem(item);

      if (edit.status === "Delivered") {
        setmessage("shipment closed");
        handleShowmessage();
        return;
      }

      handleShowedit();
    },
    []
  );

  //logic to handle closing of shipment
  const handleCloaseshipment = () => {};

  //logic to update shipment

  const handleUpdate = async (e) => {
    e.preventDefault();
    const id = database.collection("_").doc().id;
    
    const data = {
      id: id,
      trackid: edit.id,
      firstname: edit.firstname,
      lastname: edit.lastname,
      email: edit.email,
      address1: edit.address1,
      personalphone: edit.personalphone,
      weigth: edit.weigth,
      payment: edit.payment,
      contactname: edit.contactname,
      contactnumber: edit.contactnumber,
      item: location,
      contactemail: edit.contactemail,
      comment: remark,
      contactAddress : edit.contactAddress,
      shipmentDescriptiom: edit.shipmentDescriptiom,
      Destination: edit.Destination,
      AccessKey: edit.AccessKey,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      deliverydate: newdate,
      deliverydays: 0,
      trackNo: edit.trackNo,
      trackurl: edit.trackurl,
      PickUpdate:pictupdate,
    };

    try {
      setlaoding(true);
      await database.collection("Tracker").doc(data.id).set(data);
    } catch (error) {
      setmessage(error.message);
    }

    await database
      .doc(`services/${edit.id}`)
      .update({
        status: newstatus,
        remark: remark,
        currentdestination: location,
        deliverydate: newdate,
      })
      .then(() => {
        setmessage("Be informed that, shipment record has being updated");
      })
      .catch((e) => {
       
        setmessage(e.message);
      });
    setlaoding(false);
  };

  return (
    <div>
      <AdminHeader />
      <Modal show={showa} onHide={handleClosea}>
        <Modal.Body>
          <h1 className="workid">Create new shipment request</h1>
          <hr />
          <Form>
            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setfirstname(event.target.value);
                  }}
                  required
                />
              </Col>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  laceholder="Last name"
                  onChange={(event) => {
                    setlastname(event.target.value);
                  }}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                onChange={(event) => {
                  setphone(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setaddress1(event.target.value);
                }}
                required
              />
            </Form.Group>

            <h1 className="workid">Shipment Information</h1>

            <Row>
              <Col>
                <Form.Control
                  placeholder="Weight "
                  onChange={(event) => {
                    setweight(event.target.value);
                  }}
                  required
                />
              </Col>
              <Col>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    setpayment(event.target.value);
                  }}
                  required
                >
                  <option>Select Payement Option</option>
                  <option value="Payment on Delivery">
                    Payment on Delivery
                  </option>
                  <option value="Online Payment">Online Payment</option>
                  <option value="Bank Payement">Bank Payement</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Delivery Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(event) => {
                    setdeliverydate(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Pick-Up Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(event) => {
                    setpickupdate(event.target.value);
                  }}
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Shipment Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setitem(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Destination</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setcomment(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setcontactstate(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => {
                    setcontactstate(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setzip(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => {
                    setcity(event.target.value);
                  }}
                  required
                />
              </Form.Group>
            </Row>

            <h1 className="workid">Contact Person Information</h1>
            <hr />
            <Row>
              <Col>
                <Form.Control
                  placeholder="Contact Person Name"
                  onChange={(event) => {
                    setcontactname(event.target.value);
                  }}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="Phone Number"
                  onChange={(event) => {
                    setcontactphone(event.target.value);
                  }}
                  required
                />
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setcontactemail(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Contact Address</Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setContactAddress(event.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Any comment or concern</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setcomment(event.target.value);
                }}
                required
              />
            </Form.Group>

            {createmessage ? <Alert variant="success"><p>{createmessage}</p></Alert> : ""}

            <Button
              variant="dark"
              type="submit"
              disabled={loading}
              className="w-100 "
              onClick={handlecreaterequest}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showedit} onHide={handleCloseedit}>
        <Modal.Body>
          <div className="tractbody">
            <div className="trackdetails">
              <div className="headertracker">
                <h1>Update Shipment Information</h1>
                <button onClick={handleCloaseshipment}>
                  Close Shipment Request
                </button>
              </div>

              <div className="trackingdetailtoupdate">
                <div className="detailsrapper">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Tracker Number</Form.Label>
                      <Form.Control placeholder={edit.trackNo} disabled />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Sender Name</Form.Label>
                      <Form.Control
                        placeholder={`${edit.firstname} ${edit.lastname}`}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Reciver Name</Form.Label>
                      <Form.Control placeholder={edit.contactname} disabled />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Destination</Form.Label>
                      <Form.Control placeholder={edit.Destination} disabled />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Request Date</Form.Label>
                      <Form.Control
                        placeholder={
                          edit.created
                            ? format(edit.created, "dd/MM/yyyy hh:mm a")
                            : ""
                        }
                        disabled
                      />
                    </Form.Group>

                    <Form.Select
                      aria-label="Default select example"
                      onChange={(event) => {
                        setstatus(event.target.value);
                      }}
                    >
                      <option>Change Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Pick-Up">Pick-Up</option>
                      <option value="In Transit">In Transit</option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Delivered">Delivered</option>
                    </Form.Select>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Expected Delivery Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(event) => {
                          setDeliverdate(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Expected PickUp date Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(event) => {
                          setPickUpDate(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Current Location</Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(event) => {
                          setcurrentloacation(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label> Remark</Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(event) => {
                          setremark(event.target.value);
                        }}
                        required
                      />
                    </Form.Group>

                    {message ? <Alert variant="success">{message}</Alert> : ""}

                    <Button
                      variant="dark"
                      disabled={loading}
                      className="w-100 py-2"
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default Dasboard;
