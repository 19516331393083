import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { ListGroup, Table } from "react-bootstrap";
import { getalltraker } from "../../Services/GetUser.service";
import Navbar from "../Client/Navbar";
import "./Shipment.css";

function Shipmentdetails(props) {
  const [loading, setlaoding] = useState(false);
  const [message, setmessage] = useState("");
  const [item, setitem] = useState([]);
  const [items, setitems] = useState([]);
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);

  useEffect(() => {
    const trackNo = props.match.params.trackNo;
    getalltraker(trackNo, (requests) => {
      // setloading(true);
      const request = requests;
      if (request.length === 0) {
        setlaoding(true);
        setmessage("Bad Request");
      } else {
        setitem(request[0]);
        updateState(request);
        setlaoding(false);
      }
    });
  }, []);

  

  const updateState = (users) => {
    const isItemEmpty = users.length === 0;
    if (!isItemEmpty) {
      const lastDoc = users[users.length - 1];
      setitems(users);
      setlastDocs(lastDoc);
    } else {
      setisEmpty(true);
    }
    setlaoding(false);
  };
  const fetchMore = () => {
    setlaoding(true);
    getalltraker(lastDocs).then((collections) => {
      updateState(collections);
    });
  };

 

  return (
    <div>
      <Navbar />
      <div
        className="shipmentinformatio"
        style={{ backgroundImage: "url(/img/b.jpg)" }}
      >
        <div className="shipmenmtcontener">
          <div className="shopmenrcontner11">
            <div className="shopmnerheader">
              <p>Tracking Number</p>
              <h3>{item.trackNo}</h3>
            </div>

            <div className="shpermwnroform">
              <div className="shiperinformaion">
                <h2>Shipper's Information</h2>
                <hr className="hrodmd" />
                <p>
                  {item.lastname} {item.firstname}
                </p>
                <p>{item.address1}</p>
                <p>{item.email} </p>
                <p>{item.personalphone} </p>
              </div>

              <div className="shiperinformaion">
                <h2>Recipient's Information</h2>
                <hr className="hrodmd" />
                <p>{item.contactname}</p>
                <p>{item.contactAddress}</p>
                <p>{item.contactemail} </p>
                <p>{item.contactnumber} </p>
              </div>
            </div>

            <div className="shipmentstatus">
              <h2>Shipment Status :</h2>
              {item.status}
            </div>

            <div className="shipementdetails">
              <div className="detainsconta">
                <h2>Shipment Information</h2>
                <hr className="hrodmd" />
                <div className="contaitem">
                  <div className="conteneralshipment">
                    <h1>Origin:</h1>
                    <p>{item.address1}</p>
                    <h1>Destination:</h1>
                    <p>{item.Destination}</p>

                    <h1>Weight:</h1>
                    <p>{item.weigth}</p>

                    <h1>Product:</h1>
                    <p>{item.shipmentDescriptiom}</p>
                  </div>

                  <div className="conteneralshipment">
                    <h1>Delivery Date:</h1>
                    <p>{item.deliverydate}</p>

                    <h1>Pick-up Date:</h1>
                    <p>{item.PickUpdate}</p>

                    <h1>Comments:</h1>
                    <p>{item.comment}</p>

                    <h1>Payment Method:</h1>
                    <p>{item.payment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shipmenthistry">
            <center><h2>Track History</h2></center>
            <div className="histortconetnt">
              <ListGroup className="lastbidjdfbhf">
                {items.map((item, id) => {
                  return (
                    <div key={id}>
                      <ListGroup.Item>
                        Date :{" "}
                        {item.created
                          ? format(item.created, "dd/MM/yyyy hh:mm a")
                          : ""}
                      </ListGroup.Item>
                      <ListGroup.Item>comment : {item.comment}</ListGroup.Item>
                      <ListGroup.Item>
                        Location : {item.item}
                        
                      <hr  className="text-danger fw-bolder sdssline"/>
                      </ListGroup.Item>

                    </div>
                  );
                })}
              </ListGroup>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shipmentdetails;
