
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage'
import "firebase/auth"


const FirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDmVMbmY3-bglWefQEVUmr8wdqAwxWcfpw",
  authDomain: "transcargo-app.firebaseapp.com",
  projectId: "transcargo-app",
  storageBucket: "transcargo-app.appspot.com",
  messagingSenderId: "809508764950",
  appId: "1:809508764950:web:952e39c5069408a72ae437"
});

export const auth = FirebaseApp.auth()
export const database = FirebaseApp.firestore();
const storage = firebase.storage()



export  {
  storage,  FirebaseApp as default
}