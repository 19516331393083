import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { getallTracker } from '../../Services/GetUser.service';
import AdminHeader from './AdminHeader';
import SideMenue from './SideMenu';

function TrackerHistory() {
  
    const [userCollection, setuserCollection] = useState([]);
    const [lastDocs, setlastDocs] = useState();
    const [isEmpty, setisEmpty] = useState(false);
    const [showa, setShowa] = useState(false);
    const [loading, setlaoding] = useState(false);



    useEffect(() => {
        getallTracker(null).then((collections) => {
          updateState(collections);
        });
      }, []);

    

      const updateState = (users) => {
        const isItemEmpty = users.length === 0;
        if (!isItemEmpty) {
          const lastDoc = users[users.length - 1];
          setuserCollection(users);
          setlastDocs(lastDoc);
        } else {
          setisEmpty(true);
        }
        setlaoding(false);
      };
      const fetchMore = () => {
        setlaoding(true);
        getallTracker(lastDocs).then((collections) => {
          updateState(collections);
        });
      };

  return (
    <div>
   <SideMenue/>
      <div className="accountiformationheader">
        <div className="textalejdjd">
          <h1>Control Panel</h1>
          <p> Tracker History</p>
        </div>

        <div className="shipmentconatiner">
          <div className="creatnewshipment">
          </div>
          <div className="histortconetnt">
          <Table striped>
              <thead>
                <tr className="bg-dark text-light">
                  <th>#</th>
                  <th>Date</th>
                  <th>Sender Name</th>
                  <th>Receiver Name</th>
                  <th>Receiver Number</th>
                  <th>Location</th>
                  <th>Remark</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userCollection.map((item, id) => {
                  return (
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td>
                        {item.created
                          ? format(item.created, "dd/MM/yyyy hh:mm a")
                          : ""}
                      </td>
                      <td>
                        {item.lastname} {item.firstname}
                      </td>
                      <td>{item.contactname}</td>
                      <td>{item.contactnumber}</td>
                      <td>{item.Destination}</td>
                      <td>{item.comment}</td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {loading && <div className="text-center"> loading ... </div>}
            {!loading && !isEmpty && (
              <div className="text-center textsss">
                <button onClick={fetchMore}>see more</button>
              </div>
            )}
            {isEmpty && <h1 className="hshhs">no more data to fetch</h1>}
          </div>
        </div>
      </div>
    </div>
  )
}


export default TrackerHistory