import React from "react";
import {
  TiSocialFacebookCircular,
  TiSocialTwitterCircular,
  TiSocialYoutube,
  TiSocialInstagram,
} from "react-icons/ti";

import "./Footer.css"

function Footer() {
  return (
    <div>
      <div className="footerheader">
        <div className="footerconteainer">
          <div className="footeitem">
            <div className="imageside">
              <div className="icons">
                <TiSocialFacebookCircular />
              </div>

              <div className="icons">
                <TiSocialTwitterCircular />
              </div>

              <div className="icons">
                <TiSocialYoutube />
              </div>

              <div className="icons">
                <TiSocialInstagram />
              </div>
            </div>
            <div className="messageside">
              <h1>Connect with TransCargo   Logistics</h1>
              <p>
                Stay connected on{" "}
                <span className="socla">
                  Instagram, Facebook, Twitter, LinkedIn{" "}
                </span>{" "}
                or for industry news and TransCargo   updates.
              </p>
            </div>
          </div>

          <div className="feetnoot">
            <div className="sjehgdy">News & Updates</div>
            <div className="sjehgdy">Privacy Policy</div>
            <div className="sjehgdy">Forms-Downloads</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
