import React, { useContext, useState, useEffect, createContext} from "react";
import { auth } from "../../firebase";
import firebase from "firebase";


const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children}) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

   function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }
 
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function sendEmailVerification (email){
    return auth().currentUser.sendEmailVerification(email)
  }

  auth.onAuthStateChanged((user) => {
    setCurrentUser(user);
  });

  function logout() {
   return auth.signOut()
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {

      if (user){
        setCurrentUser(user);
      }else{
        auth.signOut()
      } 
      setLoading(false);
    });

    return unsubscribe;
  }, [currentUser]);


  const value = {
    currentUser : currentUser,
    signup,
    resetPassword,
    login,
    logout,
    sendEmailVerification
  
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
