import React from "react";
import "./Leading.css"

function Leasding() {
  return (
    <div>
      <div className="leadingconteiont">
        <div className="leadingconcetentr">
          <div className="leadjdbody">
            <div className="headersection">
              <h1>The leading digital freight network</h1>
              <p>
                We use technology to make freight more efficient, reducing costs
                for some of the nation’s largest brands, increasing earnings for
                carriers, and eliminating carbon emissions from our planet.
              </p>
            </div>

            <div className="bodyleadingsection">
              <div class="content">
                <div class="grid">
                  <figure class="effect-lily">
                    <img
                      src="/img/smile.jpg"
                      alt="img12"
                    />
                    <figcaption>
                      <div className="alnewjsjs">
                        <h2>
                          Reducing  &nbsp;<span>shippers' &nbsp;costs</span>
                        </h2>
                  
                      </div>
                   
                    </figcaption>
                  </figure>

                  <figure class="effect-lily">
                    <img
                      src="/img/newpac.jpeg"
                      alt="img12"
                    />
                    <figcaption>
                      <div className="alnewjsjs">
                        <h2>
                          Quick &nbsp; &  &nbsp; Express &nbsp; <span> Delivery</span>
                        </h2>
                      </div>
                      
                    </figcaption>
                  </figure>

                  <figure class="effect-lily">
                    <img
                      src="/img/truck.jpg"
                      alt="img12"
                    />
                    <figcaption>
                      <div className="alnewjsjs">
                        <h2>
                          Rank &nbsp; &nbsp; Among <span> the Best</span>
                        </h2>
                       
                      </div>
                     
                    </figcaption>
                  </figure>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leasding;
