import React, { useState } from "react";
import "./MyCss.css";
import { useHistory} from "react-router-dom";
import { useAuth } from "../CreateUser.js/UserAuth";
import firebase from "firebase";
import { database } from "../../firebase";
import "../Client/Navbar"
import Navbar from "../Client/Navbar"
import { Alert , Form, Button} from "react-bootstrap";

function Register() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [number, setnumber] = useState("");
  const [loading, setloading] = useState(false)
  const { signup } = useAuth();
  const [message, setmessage] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");

  const history =  useHistory()

  const handleLogin = () => {
    history.push("/auth/login");
  };

  const hanelRegister = async (e) => {
    e.preventDefault()
   
    if (
      number === "" ||
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      password === "" ||
      confirmpassword === ""
    ) {
      setmessage("incomplet information");
      setmessage(message)
    } else {
      setmessage("");
      
    }

    if (password !== confirmpassword) {
      setmessage("passoword Mismatch");
      setmessage(message)
    } else {
      setmessage("");
      
    }
    try {
      // setloading(true)
      const cred = await signup(email, password);
  
      const { uid } = cred.user;

      const user = {
        id: uid,
        firstname: firstname,
        lastname:lastname,
        email: email,
        phone: number,
        active: true,
        permission: "admin", 
        wallet: 0,
        totalspent: 0,
        totalrequest:0, 
        amountspend:0,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };
      await database.collection("user").doc(user.id).set(user).then((e)=>{
        setmessage("Great ! your registration was successful. Redirection in process .... 10%");
        setInterval(() => {
          history.push("/checker")
        }, 2000);
      }).catch((e)=>{
       
      });
    } catch (error) {
      setmessage(error.message);
  
    }
    setloading(false)
  };
  return (
    <div>
<Navbar/>
<div className="backgroisns"  style={{ backgroundImage: "url(/img/b.jpg)" }}>
      
      <div className="usercontainerbody">
      <div class="usercontainer">
        <div class="userheader">Register</div>
        {message ? <Alert variant="info">
     
     <p>
      {message}
     </p>
   </Alert>: ""}

   <Form>
   <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" placeholder="Last name"     onChange={(event) => {
                setfirstname(event.target.value);
              }} required/>
      
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" placeholder="First name"     onChange={(event) => {
                setlastname(event.target.value);
              }} required/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email"     onChange={(event) => {
                setemail(event.target.value);
              }} required/>

      </Form.Group>
      
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter Phone Number</Form.Label>
        <Form.Control type="tel" placeholder="+123 - 999 - 999"     onChange={(event) => {
                  setnumber(event.target.value);
                }} required/>

      </Form.Group>

     
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password"       onChange={(event) => {
                  setpassword(event.target.value);
                }} required/>
      </Form.Group>

         
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label> Confirm Password</Form.Label>
        <Form.Control type="password" placeholder="Password"   onChange={(event) => {
                  setconfirmpassword(event.target.value);
                }} required/>
      </Form.Group>

       <Form.Text className="text-muted">
        <span>Alreading have an account?</span>{" "}
              <span className="act" onClick={handleLogin}>
                Login
              </span>
        </Form.Text>

      <Button variant="dark" type="submit" onClick={hanelRegister}  className="w-100 py-2 my-2">
      register
      </Button>
    </Form>
  
      </div>
      </div>
    </div>

</div>
  );
}

export default Register;
